<template>
    <div>
        <div class="border-t">
            <Breadcrumb />
        </div>
        <keep-alive :include="include">
            <router-view class="mt-2 mb-2"></router-view>
        </keep-alive>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
    components: {
        Breadcrumb,
    },
    computed: {
        ...mapState("i18nStore", ["langDatas"]),
    },
    data() {
        return {
            include: ["FightingRules", "EditRule"],
        };
    },
    beforeRouteLeave(to, from, next) {
        // location.reload();
        return next();
    },
};
</script>
<style lang="scss" scoped></style>
