<template>
    <div class="bg-white py-2 px-5 h-10 flex items-center">
        <ul class="linst-none flex">
            <router-link
                v-for="(item, index) in crumbList"
                v-show="item.name !== 'layout'"
                :key="index"
                v-slot="{ href }"
                :to="{ name: item.name }"
                custom>
                <li>
                    <a v-if="item.meta.breadcrumb !== undefined" :href="href">
                        <span
                            v-for="(text, index) in item.meta.breadcrumb"
                            :key="index"
                            :class="index + 1 === item.meta.breadcrumb.length ? 'text-orange-600' : ''">
                            {{ text }}
                            <span v-show="index + 1 !== item.meta.breadcrumb.length" class="mx-1">・</span>
                        </span>
                    </a>
                    <a v-else :class="item.name === $route.name ? 'text-orange-600' : ''" :href="item.usePath ? item.path : href">
                        {{ item.meta.text }}
                        <span v-show="index + 1 !== crumbList.length" class="mx-1">・</span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "Breadcrumb",
    computed: {
        ...mapState("breadcrumbStore", ["crumbList"]),
        ...mapState("i18nStore", ["langData"]),
    },
};
</script>
